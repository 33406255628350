import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from '../reducers/settings.reducer';

export const selectSettingsState =
  createFeatureSelector<SettingsState>('settings');

export const selectMinWithdrawValue = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.minWithdrawValue,
);

export const selectMaxWithdrawValue = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.maxWithdrawValue,
);

export const selectAppLocale = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.locale,
);
