import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { PlayerWrapper } from '../../common/models/wrappers/player.wrapper';
import {
  selectAddress,
  selectPlayerWrapper,
} from './selectors/player.selector';
import { filter, map, tap } from 'rxjs/operators';
import { Address } from 'viem';
import {
  selectAppLocale,
  selectMaxWithdrawValue,
  selectMinWithdrawValue,
} from './selectors/settings.selector';
import Helper from '../../common/helpers/helper';
import { Token } from '../../common/models';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(private readonly store: Store) {}

  private playerWrapper: Observable<PlayerWrapper> = this.store
    .select(selectPlayerWrapper)
    .pipe(
      filter((data: PlayerWrapper | null) => data !== null),
    ) as Observable<PlayerWrapper>;

  private currencies: Observable<Token[]> = this.playerWrapper.pipe(
    map((data) => {
      if (data?.currencies) {
        return Helper.transformRecord(data.currencies);
      }
      return [];
    }),
  );

  private name: Observable<string | undefined> = this.playerWrapper.pipe(
    map((data) => data?.name),
  );

  private address: Observable<Address | undefined> =
    this.store.select(selectAddress);

  getPlayerName(): Observable<string | undefined> {
    return this.name;
  }

  getPlayerMetaBalance(): Observable<number> {
    return this.playerWrapper.pipe(
      map((data) => {
        if (data?.currencies) {
          return data.currencies['GGMT'].amount;
        }
        return 0;
      }),
    );
  }

  getAddress(): Observable<Address | undefined> {
    return this.address;
  }

  getWithdrawLimits(): Observable<{ min: number | null; max: number | null }> {
    return combineLatest({
      min: this.store.select(selectMinWithdrawValue),
      max: this.store.select(selectMaxWithdrawValue),
    });
  }

  getAppLocale(): Observable<string> {
    return this.store.select(selectAppLocale);
  }

  getPlayerWrapper(): Observable<PlayerWrapper> {
    return this.playerWrapper;
  }

  getPlayerCurrencies(): Observable<Token[]> {
    return this.currencies;
  }
}
